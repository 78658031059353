// @ts-nocheck
import {Box, Stack, styled, Typography} from "@mui/material";

export const MAIN_WIDTH = 472;
export const SUBSCRIPTION_PLANS_WIDTH = 1150;

export const ResponsiveContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100vw;
  justify-content: center;
  
  @media only screen and (max-width: ${MAIN_WIDTH}px) {
    padding:  152px 40px;
  }

  @media only screen and (min-width: ${MAIN_WIDTH}px) {
    padding: 152px min(20vw, calc((100vw - 440px)/2));
  }
  
  #general-agreement {
    a {
      text-decoration: none;
      color: ${({ theme }) => theme.palette.primary.main};
      
      :active {
        text-decoration: none;
      }
    }
  }
`;

export const AccordionNumberContainer = styled(Box)`
  font-family: Roboto;
  font-size: 35px;
  font-weight: 700;
  line-height: 41px;
  transform: ${({ isSmallScreen }) => isSmallScreen ? 'translateY(-50%)' : 'none'};
  background-color: ${({ isSmallScreen }) => isSmallScreen ? 'white' : 'white'};
  border: 2px dashed ${({ disabled }) => disabled ? '#77787B' : '#FF0000'};
`;

const AccordionNumber = ({ index, disabled, isSmallScreen }) => (
  <AccordionNumberContainer
    borderRadius="100%"
    color={disabled ? '#77787B' : '#FF0000'}
    height="64px"
    width="64px"
    position="absolute"
    left={isSmallScreen ? '-30px' : '-3px'}
    top={isSmallScreen ? '50%' : '-2px'}
    display="flex"
    justifyContent="center"
    alignItems="center"
    isSmallScreen={isSmallScreen}
  >
    {index}
  </AccordionNumberContainer>
);

const AccordionHeaderContainer = styled(Box)<{
  isMobile: boolean,
  disabled: boolean
}>`
  display: grid;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  min-height: 60px;
  padding-left: ${({ isMobile }) => isMobile ? '45px' : '90px'};
  padding-right: 25px;
  border-radius: 30px;
  
  &:hover {
    cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  }
  
  &::before {
    content: "";
    position: absolute;
    top: -2px; // border: 2px + offset: 1px
    right: -2px; // border: 2px + offset: 1px
    bottom: -2px; // border: 2px + offset: 1px
    left: -2px; // border: 2px + offset: 1px
    border: 2px dashed ${({ disabled }) => disabled ? '#77787B' : '#231F20'};
    border-radius: 30px;
  }
`;

const Arrow = () => (
    <svg width="28" height="12" viewBox="0 0 28 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.4747 11.0032C13.2055 11.0032 12.9362 10.9295 12.7006 10.7823L0.64362 3.12732C-0.0126596 2.71022 -0.197764 1.85966 0.231342 1.22174C0.660447 0.592002 1.53549 0.412076 2.19177 0.829177L13.4495 7.96895L24.7829 0.248508C25.4224 -0.18495 26.3058 -0.0377378 26.7518 0.583824C27.1977 1.20538 27.0462 2.06412 26.4068 2.49758L14.2909 10.7496C14.0384 10.9214 13.7608 11.0032 13.4747 11.0032Z" fill="#77787B"/>
    </svg>
);

const ExpandedArrow = () => (
  <svg width="28" height="12" viewBox="0 0 28 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.1905 11.2788C13.9213 11.2788 13.6521 11.2052 13.4165 11.058L1.35944 3.40295C0.703161 2.98585 0.518056 2.13529 0.947162 1.49738C1.37627 0.867637 2.25131 0.687711 2.90759 1.10481L14.1653 8.24459L25.4987 0.524142C26.1382 0.090685 27.0216 0.237897 27.4676 0.859458C27.9135 1.48102 27.7621 2.33976 27.1226 2.77321L15.0067 11.0253C14.7543 11.197 14.4766 11.2788 14.1905 11.2788Z" fill="#231F20"/>
  </svg>
);

export const AccordionHeader = ({
    index,
    text,
    onClick,
    disabled,
    isSmallScreen,
    isMobile,
}) => {
    return (
        <AccordionHeaderContainer
          onClick={
            disabled
              ? () => void 0
              : onClick
          }
          disabled={disabled}
          isMobile={isMobile}
        >
            <AccordionNumber index={index} disabled={disabled} isSmallScreen={isSmallScreen} />
            <Stack
                justifyContent="space-between"
                direction="row"
                alignItems="center"
                flex="1 1 auto"
                height="fit-content"
            >
                <Typography
                  variant="h4"
                  color={disabled ? '#77787B' : '#231F20'}
                  fontSize={isMobile && '22px'}
                >
                  {text}
                </Typography>
              {disabled ? <Arrow /> : <ExpandedArrow/>}
            </Stack>
        </AccordionHeaderContainer>
    );
};

export const StyledAccordion = ({
  index,
  isOpen,
  text,
  onChange,
  disabled,
  children,
  isSmallScreen,
  isMobile,
}) => {
    return (
      <Stack>
        <AccordionHeader
          index={index}
          text={text}
          onClick={onChange}
          disabled={disabled}
          isSmallScreen={isSmallScreen}
          isMobile={isMobile}
        />
          {isOpen ? (
            <Stack justifyContent="center" alignItems="center">
              <Box mb={7} />
              {children}
              <Box mb={7} />
            </Stack>
          ) : <Box mb={4} />}
      </Stack>
    );
};

export const AgreementContainer = styled(Stack)`
  border: dashed #FF0000;
  padding: 35px 32px;
  width: 80%;
  height: 50vh;
`;

export const AgreementText = styled(Box)`
  font-family: Roboto, serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: 0;
  text-align: left;
  color: #77787B;
  overflow-y: scroll;
  white-space: break-spaces;
`;
