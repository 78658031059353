import { useMutation, UseMutationOptions, UseMutationResult } from "react-query";
import { UserData } from "../components/Registration/Registration.types";
import axios, { AxiosError, AxiosResponse } from "axios";

type UseSaveRegistrationData = (
  options?: Omit<UseMutationOptions<AxiosResponse<void>, AxiosError, UserData>, 'mutationFn'>
) => UseMutationResult<AxiosResponse, AxiosError, UserData>;

export const useSaveRegistrationData: UseSaveRegistrationData = (options) => {
  return useMutation(
    (userData: UserData) => axios.post('/save-registration-data', userData),
    options,
  );
}