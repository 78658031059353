import {InputBase, InputLabel, Stack, styled} from "@mui/material";

export const RegistrationFormContainer = styled(Stack)`
  border: dashed #FF0000;
  max-width: 650px;
  width: 100%;
  padding: 25px;
`;

export const StyledFormInputLabel = styled(InputLabel)`
  font-family: Roboto, serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: black;
  margin-bottom: 10px;
`;

export const StyledFormInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: '#F8F8F8',
    border: '1px solid #FF0000',
    width: '100%',
    padding: '4px 14px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
  },
}));