// @ts-nocheck
import { Box, Theme, Typography } from "@mui/material";
import { Iconify } from "../SubscriptionPlan/SubscriptionPlan.styles";
import confirmationSign from "../../assets/confirmationSign.svg";
import mailIcon from '../../assets/mailIcon.svg';
import {StyledSuccessLink} from "../Canceled/Canceled.styles";
import {SuccessBorder} from "../Success/Success.styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MAIN_WIDTH } from "../Registration/Registration.styles";

export const SentMail = () => {
  const isMobile = useMediaQuery(`(max-width:${MAIN_WIDTH}px)`);
  
  return (
    <Box
      height={!isMobile && "100vh"}
      py={isMobile && '10vh'}
      width="100vw"
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
    >
      <SuccessBorder alignItems="center">
        <img src={confirmationSign} alt="Confirmation sign" />
        <Box mb={2} />
        <Typography variant="success1" fontSize={isMobile && '35px'}>
          Congratulations!
        </Typography>
        <Box mb={4} />
        <Typography variant="success2" fontSize={isMobile && '26px'}>
          Your invoice is on the way,<br/> please check your email.
        </Typography>
        <Box mb={2} />
        <img src={mailIcon} alt="Mail icon" width={51} height={51} />
        <Box mb={2} />
        <Typography variant="success2" fontSize={isMobile && '26px'}>
          As soon as we receive your payment, you will immediately have full access to Report Adviser.
        </Typography>
      </SuccessBorder>
    </Box>
  );
}
