import {Stack, styled} from "@mui/material";

export const SuccessBorder = styled(Stack)`
  border: dashed #7C7C7B;
  border-radius: 4px;
  padding: 45px;
  position: relative;
  max-width: min(750px, 80vw);
  
  img:first-child {
    position: absolute;
    z-index: 2;
    background: #f9fafb;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
