import {PlanBorderComponent} from "./PlanBorder.types";
import {StyledPlanBorder} from "./PlanBorder.styles";

export const PlanBorder: PlanBorderComponent = ({
  isSelected
}) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 318 446"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StyledPlanBorder
        d="M1.18186 431.43V20.0042C1.18186 20.0042 1.18186 0.866325 25.0555 0.866325H297.672C297.672 0.866325 316.818 0.866326 316.818 24.8084V292.66C316.818 292.66 316.818 316.603 292.944 316.603H144.66L25.1343 440.96C25.1343 440.96 5.98811 455.293 1.18186 431.43Z"
        fill={isSelected ? '#D1D3D4' : 'none'}
      />
      <path d="M2.36373 239.657H0V249.029H2.36373V239.657Z" fill="#231F20"/>
      <path d="M2.36373 220.992H0V230.364H2.36373V220.992Z" fill="#231F20"/>
      <path d="M2.36373 258.322H0V267.695H2.36373V258.322Z" fill="#231F20"/>
      <path d="M2.36373 71.59H0V80.8833H2.36373V71.59Z" fill="#231F20"/>
      <path d="M2.36373 34.2592H0V43.5525H2.36373V34.2592Z" fill="#231F20"/>
      <path d="M2.36373 108.999H0V118.372H2.36373V108.999Z" fill="#231F20"/>
      <path d="M2.36373 202.326H0V211.698H2.36373V202.326Z" fill="#231F20"/>
      <path d="M2.36373 164.996H0V174.368H2.36373V164.996Z" fill="#231F20"/>
      <path d="M2.36373 146.33H0V155.702H2.36373V146.33Z" fill="#231F20"/>
      <path d="M2.36373 183.661H0V193.033H2.36373V183.661Z" fill="#231F20"/>
      <path d="M2.36373 52.9246H0V62.2179H2.36373V52.9246Z" fill="#231F20"/>
      <path d="M2.36373 295.653H0V305.025H2.36373V295.653Z" fill="#231F20"/>
      <path d="M2.36373 332.984H0V342.356H2.36373V332.984Z" fill="#231F20"/>
      <path d="M2.36373 314.319H0V323.691H2.36373V314.319Z" fill="#231F20"/>
      <path d="M2.36373 276.988H0V286.36H2.36373V276.988Z" fill="#231F20"/>
      <path d="M2.36373 388.98H0V398.352H2.36373V388.98Z" fill="#231F20"/>
      <path d="M2.36373 351.649H0V361.021H2.36373V351.649Z" fill="#231F20"/>
      <path d="M2.36373 407.724H0V417.017H2.36373V407.724Z" fill="#231F20"/>
      <path d="M2.36373 90.2553H0V99.5487H2.36373V90.2553Z" fill="#231F20"/>
      <path d="M2.36373 370.315H0V379.687H2.36373V370.315Z" fill="#231F20"/>
      <path d="M2.36373 127.665H0V137.037H2.36373V127.665Z" fill="#231F20"/>
      <path d="M13.0793 4.5679L12.1338 2.36271C9.21853 3.70157 6.77602 5.51298 4.88503 7.79693L6.69722 9.29331C8.35183 7.32439 10.558 5.74925 13.0793 4.5679Z" fill="#231F20"/>
      <path d="M283.096 0H273.326V2.36271H283.096V0Z" fill="#231F20"/>
      <path d="M146.787 0H137.017V2.36271H146.787V0Z" fill="#231F20"/>
      <path d="M263.634 0H253.864V2.36271H263.634V0Z" fill="#231F20"/>
      <path d="M49.4807 0H39.7106V2.36271H49.4807V0Z" fill="#231F20"/>
      <path d="M88.3246 0H78.6333V2.36271H88.3246V0Z" fill="#231F20"/>
      <path d="M68.8632 0H59.1719V2.36271H68.8632V0Z" fill="#231F20"/>
      <path d="M127.326 0H117.556V2.36271H127.326V0Z" fill="#231F20"/>
      <path d="M107.786 0H98.0946V2.36271H107.786V0Z" fill="#231F20"/>
      <path d="M166.249 0H156.479V2.36271H166.249V0Z" fill="#231F20"/>
      <path d="M205.25 0H195.48V2.36271H205.25V0Z" fill="#231F20"/>
      <path d="M224.712 0H214.942V2.36271H224.712V0Z" fill="#231F20"/>
      <path d="M244.173 0H234.403V2.36271H244.173V0Z" fill="#231F20"/>
      <path d="M185.789 0H176.019V2.36271H185.789V0Z" fill="#231F20"/>
      <path d="M313.351 13.0736L315.479 12.1286C314.218 9.29331 312.406 6.85185 310.121 4.88292L308.545 6.69433C310.672 8.34823 312.248 10.4747 313.351 13.0736Z" fill="#231F20"/>
      <path d="M318 249.502H315.636V259.031H318V249.502Z" fill="#231F20"/>
      <path d="M318 173.108H315.636V182.637H318V173.108Z" fill="#231F20"/>
      <path d="M318 268.64H315.636V278.169H318V268.64Z" fill="#231F20"/>
      <path d="M318 211.305H315.636V220.834H318V211.305Z" fill="#231F20"/>
      <path d="M318 230.443H315.636V239.972H318V230.443Z" fill="#231F20"/>
      <path d="M318 192.245H315.636V201.775H318V192.245Z" fill="#231F20"/>
      <path d="M318 77.5755H315.636V87.1051H318V77.5755Z" fill="#231F20"/>
      <path d="M318 39.3784H315.636V48.908H318V39.3784Z" fill="#231F20"/>
      <path d="M318 96.7134H315.636V106.243H318V96.7134Z" fill="#231F20"/>
      <path d="M318 115.773H315.636V125.302H318V115.773Z" fill="#231F20"/>
      <path d="M318 134.91H315.636V144.44H318V134.91Z" fill="#231F20"/>
      <path d="M318 58.5163H315.636V68.0459H318V58.5163Z" fill="#231F20"/>
      <path d="M318 154.048H315.636V163.578H318V154.048Z" fill="#231F20"/>
      <path d="M305.787 311.877L307.048 313.846C309.806 312.035 312.169 309.672 313.903 306.679L311.854 305.498C310.357 308.097 308.309 310.223 305.787 311.877Z" fill="#231F20"/>
      <path d="M260.483 315.185H251.185V317.548H260.483V315.185Z" fill="#231F20"/>
      <path d="M223.451 315.185H214.154V317.548H223.451V315.185Z" fill="#231F20"/>
      <path d="M278.999 315.185H269.701V317.548H278.999V315.185Z" fill="#231F20"/>
      <path d="M204.935 315.185H195.638V317.548H204.935V315.185Z" fill="#231F20"/>
      <path d="M241.967 315.185H232.669V317.548H241.967V315.185Z" fill="#231F20"/>
      <path d="M186.34 315.185H177.043V317.548H186.34V315.185Z" fill="#231F20"/>
      <path d="M167.825 315.185H158.527V317.548H167.825V315.185Z" fill="#231F20"/>
      <path d="M67.3837 394.857L60.7194 401.78L62.4227 403.418L69.087 396.495L67.3837 394.857Z" fill="#231F20"/>
      <path d="M40.8612 422.474L34.1968 429.398L35.9001 431.036L42.5645 424.112L40.8612 422.474Z" fill="#231F20"/>
      <path d="M120.538 339.625L113.874 346.549L115.577 348.187L122.241 341.263L120.538 339.625Z" fill="#231F20"/>
      <path d="M133.8 325.872L127.136 332.795L128.839 334.434L135.504 327.51L133.8 325.872Z" fill="#231F20"/>
      <path d="M107.278 353.489L100.613 360.413L102.317 362.051L108.981 355.127L107.278 353.489Z" fill="#231F20"/>
      <path d="M54.1214 408.61L47.457 415.534L49.1603 417.172L55.8247 410.248L54.1214 408.61Z" fill="#231F20"/>
      <path d="M93.9607 367.299L87.2963 374.223L88.9996 375.861L95.664 368.937L93.9607 367.299Z" fill="#231F20"/>
      <path d="M80.7005 381.047L74.0362 387.97L75.7395 389.608L82.4038 382.685L80.7005 381.047Z" fill="#231F20"/>
      <path d="M13.4732 443.637C10.9519 443.637 8.82458 442.771 7.09118 441.117L5.43657 442.85C7.64272 444.976 10.3216 446 13.3944 446H13.7096L13.6308 443.637H13.4732Z" fill="#231F20"/>
      <path d="M292.866 315.185H288.217V317.548H292.866C294.599 317.548 296.175 317.469 297.751 317.154L297.357 314.791C296.017 315.106 294.442 315.185 292.866 315.185Z" fill="#231F20"/>
      <path d="M292.787 0V2.36271H297.672C297.672 2.36271 299.327 2.36271 301.611 2.99276L302.242 0.708812C299.642 0 297.751 0 297.672 0H292.787Z" fill="#231F20"/>
      <path d="M315.636 292.503C315.636 292.503 315.636 294.393 315.085 296.992L317.448 297.465C318 294.629 318 292.66 318 292.503V287.699H315.636V292.503Z" fill="#231F20"/>
      <path d="M140.484 319.044L142.139 320.698L145.133 317.548H149.309V315.185H144.109L140.484 319.044Z" fill="#231F20"/>
      <path d="M2.36373 20.3193C2.36373 20.3193 2.36373 18.6654 2.99405 16.4602L0.709118 15.7514C0 18.3503 0 20.2405 0 20.3193V24.9659H2.36373V20.3193Z" fill="#231F20"/>
      <path d="M24.3464 439.699C24.0312 439.936 22.7706 440.802 21.116 441.668L22.1402 443.795C24.3464 442.692 25.7646 441.59 25.8434 441.59L29.3102 438.046L27.5768 436.313L24.3464 439.699Z" fill="#231F20"/>
      <path d="M2.36373 426.311H0V430.957H1.18186L0 431.272C0.315164 432.769 0.630327 434.108 1.10307 435.368L3.38801 434.659C2.99405 433.556 2.67889 432.296 2.44252 430.957L2.36373 426.311Z" fill="#231F20"/>
      <path d="M317.764 20.6343L315.4 20.8706C315.557 22.2094 315.636 23.5483 315.636 24.9659V29.7701H318V24.9659C318 23.5483 317.921 22.0519 317.764 20.6343Z" fill="#231F20"/>
      <path d="M20.722 0.236271L20.9584 2.59898C22.2978 2.44146 23.6373 2.36271 25.1343 2.36271H29.9405V0H25.0555C23.5585 0 22.0614 0.0787569 20.722 0.236271Z" fill="#231F20"/>
    </svg>
  );
}
