// ----------------------------------------------------------------------

type ResponsiveFontSizes = {
  sm: number,
  md: number,
  lg: number,
};

function pxToRem(value: number) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({ sm, md, lg }: ResponsiveFontSizes) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

const FONT_PRIMARY = 'Public Sans, sans-serif';

export const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },
  h2: {
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h3: {
    fontWeight: 700,
    fontFamily: 'Roboto',
    fontSize: '55px',
    lineHeight: '64px',
    letterSpacing: '0em',
    textDecorationColor: '#FF0000',
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationThickness: '2.5px',
    textUnderlineOffset: '10px',
  },
  h4: {
    fontFamily: "Roboto",
    fontSize: "35px",
    fontWeight: 700,
    lineHeight: "41px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#77787B",
  },
  h5: {
    fontFamily: "Roboto",
    fontSize: "133px",
    fontWeight: "700",
    lineHeight: "156px",
    letterSpacing: "0em",
    textAlign: "left",
    color: '#FF0000',
    marginBottom: '54px',
  },
  h6: {
    fontFamily: "Roboto",
    fontSize: "48px",
    fontWeight: "700",
    lineHeight: "56px",
    letterSpacing: "0em",
    textAlign: "center",
    color: '#77787B',
  },
  h7: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "81px",
    lineHeight: "95px",
    textAlign: "center",
    color: "#FF0000",
  },
  planCard1: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "1.768vw",
    lineHeight: "2.071vw",
    textAlign: "center",
    color: "#FFFFFF"
  },
  planCard2: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "5.051vw",
    lineHeight: "5.909vw",
    color: "#FF0000"
  },
  planCard3: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "2.020vw",
    lineHeight: "2.374vw",
    textAlign: "center",
    color: "#77787B"
  },
  planCard4: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "3.283vw",
    lineHeight: "3.838vw",
    color: "#FF0000",
    textAlign: 'left'
  },
  planCard5: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "1.010vw",
    lineHeight: "1.162vw",
    color: "#231F20",
    textAlign: 'left'
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  caption1: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "25px",
    lineHeight: "29px",
    textAlign: "center",
    color: "#231F20",
  },
  success1: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "39px",
    lineHeight: "46px",
    textAlign: "center",
    color: "#11C100",
  },
  success2: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "30px",
    lineHeight: "35px",
    textAlign: "center",
    color: "#77787B",
  },
  canceled1: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "39px",
    lineHeight: "46px",
    textAlign: "center",
    color: "#414042",
  },
  canceled2: {
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "30px",
    lineHeight: "35px",
    textAlign: "center",
    color: "#77787B",
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: 1.1,
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'capitalize',
  },
};
