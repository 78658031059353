import {RegistrationFormContainer, StyledFormInput, StyledFormInputLabel} from "./RegistrationForm.styles";
import {Box, Button, FormHelperText, Stack} from "@mui/material";
import {RegistrationFormComponent} from "./RegistrationForm.types";

export const RegistrationForm: RegistrationFormComponent = ({
  errors, values, handleChange, handleSubmit, dirty, isMobile,
}) => {
  const {
    name, registrationNumber, company, companyAddress, email, jobTitle, surname, country, sector,
  } = values;

  return (
    <form onSubmit={handleSubmit} style={isMobile ? { width: '100%' } : undefined}>
      <RegistrationFormContainer>
        <StyledFormInputLabel htmlFor="name">
          First name
        </StyledFormInputLabel>
        <StyledFormInput
          id="name"
          value={name}
          onChange={handleChange}
        />
        <FormHelperText>{errors.name}</FormHelperText>
        <Box mb={3} />
    
        <StyledFormInputLabel htmlFor="surname">
          Last name
        </StyledFormInputLabel>
        <StyledFormInput
          id="surname"
          value={surname}
          onChange={handleChange}
        />
        <FormHelperText>{errors.surname}</FormHelperText>
        <Box mb={3} />
    
        <StyledFormInputLabel htmlFor="email">
          Email
        </StyledFormInputLabel>
        <StyledFormInput
          id="email"
          value={email}
          onChange={handleChange}
        />
        <FormHelperText>{errors.email}</FormHelperText>
        <Box mb={3} />
    
        <StyledFormInputLabel htmlFor="country">
          Country
        </StyledFormInputLabel>
        <StyledFormInput
          id="country"
          value={country}
          onChange={handleChange}
        />
        <FormHelperText>{errors.country}</FormHelperText>
        <Box mb={3} />
    
        <StyledFormInputLabel htmlFor="jobTitle">
          Job title
        </StyledFormInputLabel>
        <StyledFormInput
          id="jobTitle"
          value={jobTitle}
          onChange={handleChange}
        />
        <FormHelperText>{errors.jobTitle}</FormHelperText>
        <Box mb={3} />
    
        <StyledFormInputLabel htmlFor="company">
          Company name
        </StyledFormInputLabel>
        <StyledFormInput
          id="company"
          value={company}
          onChange={handleChange}
        />
        <FormHelperText>{errors.company}</FormHelperText>
        <Box mb={3} />
    
        <StyledFormInputLabel htmlFor="companyAddress">
          Company address
        </StyledFormInputLabel>
        <StyledFormInput
          id="companyAddress"
          value={companyAddress}
          onChange={handleChange}
        />
        <FormHelperText>{errors.companyAddress}</FormHelperText>
        <Box mb={3} />
    
        <StyledFormInputLabel htmlFor="registrationNumber">
          Company registration code (VAT-number)
        </StyledFormInputLabel>
        <StyledFormInput
          id="registrationNumber"
          value={registrationNumber}
          onChange={handleChange}
        />
        <FormHelperText>{errors.registrationNumber}</FormHelperText>
        <Box mb={3} />
    
        <StyledFormInputLabel htmlFor="sector">
          Sector
        </StyledFormInputLabel>
        <StyledFormInput
          id="sector"
          value={sector}
          onChange={handleChange}
        />
        <FormHelperText>{errors.sector}</FormHelperText>
        <Box mb={3} />
    
        <Stack direction="row" justifyContent="center">
          <Button
            type="submit"
            variant="outlined"
            disabled={!!Object.keys(errors).length || !dirty}
            sx={{
              width: 'fit-content',
            }}
          >
            Submit
          </Button>
        </Stack>
      </RegistrationFormContainer>
    </form>
  );
}
