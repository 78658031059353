import { useMutation, UseMutationOptions, UseMutationResult } from "react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { SubscriptionPlanEnum, UserData } from "../components/Registration/Registration.types";

export type SendInvoiceReq = UserData & {
  plan: SubscriptionPlanEnum,
};

type UseSendInvoice = (
  options?: Omit<UseMutationOptions<AxiosResponse<void>, AxiosError, SendInvoiceReq>, 'mutationFn'>
) => UseMutationResult<AxiosResponse, AxiosError, SendInvoiceReq>

export const useSendInvoice: UseSendInvoice = (options) => {
  return useMutation(
    (userData: SendInvoiceReq) => axios.post('/send-invoice', userData),
    options,
  );
}