export type UserData = {
  name: string,
  surname: string,
  email: string,
  jobTitle: string,
  company: string,
  companyAddress: string,
  registrationNumber: string,
  country: string,
};

export enum SubscriptionPlanEnum {
  Individual = 'individual',
  Business = 'business',
  Universities = 'academics',
}