// @ts-nocheck
import {Box, Stack, Typography} from "@mui/material";
import {CanceledBorder, StyledLink} from "./Canceled.styles";
import failureIcon from '../../assets/failureIcon.svg';
import mailIcon from '../../assets/mailIcon.svg';
import useMediaQuery from "@mui/material/useMediaQuery";
import { MAIN_WIDTH } from "../Registration/Registration.styles";

export const Canceled = () => {
  const isMobile = useMediaQuery(`(max-width:${MAIN_WIDTH}px)`);
  
  return (
    <Box
      height="100vh"
      width="100vw"
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
    >
      <CanceledBorder>
        <img src={failureIcon} alt="Canceled sign" id="canceled-failure-icon" />
        <Box mb={2} />
        <Typography variant="canceled1" fontSize={isMobile && '35px'}>The payment is canceled!</Typography>
        <Box mb={4} />
        <Typography variant="canceled2" fontSize={isMobile && '26px'}>
          <StyledLink
            href="/"
            isMobile={isMobile}
          >
            Please return
          </StyledLink>
          &nbsp;and fill the form again or contact our support team:
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            overflow="hidden"
            sx={{
              overflowWrap: 'anywhere'
            }}
          >
            <img src={mailIcon} alt="Mail icon" width={26} height={26} />
            <Box mr={1.5} />
            <StyledLink href="mailto:buy@reportadviser.com" isMobile={isMobile}>
              buy@reportadviser.com
            </StyledLink>
          </Stack>
        </Typography>
      </CanceledBorder>
      
    </Box>
  );
};
