import React, { PropsWithChildren } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { alpha } from '@mui/material/styles';
import { typography } from "./typography";

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#ed1c24',
    },
    secondary: {
      main: '#363636',
    },
    info: { ...INFO },
    success: { ...SUCCESS },
    warning: { ...WARNING },
    error: { ...ERROR },
  },
  shape: {
    borderRadius: 16,
  },
  typography,
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          '&:before': {
            display: 'none',
          },
          '&.Mui-expanded': {
            margin: 0,
          },
          boxShadow: '0px 3px 1px -2px rgb(145 158 171 / 20%), 0px 2px 2px 0px rgb(145 158 171 / 14%), 0px 1px 5px 0px rgb(145 158 171 / 12%)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          background: "#FFFFFF",
          border: "1px solid #FF0000",
          borderRadius: "12px",
          fontFamily: "'Roboto'",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "26px",
          lineHeight: "30px",
          color: "#FF0000",
          ':hover': {
            background: "#FF0000",
            borderRadius: "12px",
            color: "#FFFFFF",
            fontFamily: "'Roboto'",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "26px",
            lineHeight: "30px"
          },
        },
        '&.Mui-disabled': {
          background: "#FFFFFF",
          border: "1px solid #9D9FA2",
          borderRadius: "12px",
          fontFamily: "'Roboto'",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "26px",
          lineHeight: "30px",
          color: "#7C7C7B",
        },
      }
    },
  },
};

// @ts-ignore
const theme = createTheme(themeOptions);

export const MUIProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
