import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { MUIProvider } from "./components/MUIProvider/MUIProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <MUIProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </MUIProvider>
  </React.StrictMode>
);
