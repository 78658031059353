import {Box, styled} from "@mui/material";
import { Icon } from '@iconify/react';
import React from "react";

export const Iconify: React.FC<{ icon: string }> = ({ icon, ...other }) => {
  return (
    <Box component={Icon} icon={icon} {...other} />
  );
};

export const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

export const PlanTitleWrapper = styled(Box)<{
  isLargeScreen: boolean,
  isMediumScreen: boolean,
  isSmallScreen: boolean,
}>`
  display: flex;
  justify-content: center;
  width: fit-content;
  
  background: #FF0000;
  border-radius: 1.566vw 1.566vw 0 0;
  padding: ${({ isMediumScreen }) => isMediumScreen ? `0.505vw 2.020vw` : `10px 40px`};
  color: white;

  ${({ isLargeScreen, isSmallScreen, theme }) => (isLargeScreen || isSmallScreen)
    ? (
      `font-family: 'Roboto', serif;
      font-style: normal;
      font-weight: 700;
      font-size: 44px;
      line-height: 52px;
      text-align: center;`
    ) : (
      // @ts-ignore
      theme.typography.planCard1
    )
  }
`;

export const PlanBorderContainer = styled(Box)`
  img {
    max-width: 100%;
  }
`;

export const PlanBodySeparator = styled(Box)<{
  isMediumScreen: boolean,
}>`
  img {
    position: absolute;
    left: 0;
    top:  ${({ isMediumScreen }) => isMediumScreen ? '8.7vw' : '220px'};
    max-width: 100%;
  }
`;
