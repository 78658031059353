import {Box, Stack, Typography} from "@mui/material";
import {
  PlanBodySeparator,
  PlanBorderContainer,
  PlanTitleWrapper
} from "./SubscriptionPlan.styles";
import { SubscriptionPlanComponent } from "./SubscriptionPlan.types";
import planSeparator from '../../assets/planSeparator.svg';
import {PlanBorder} from "./components/PlanBorder/PlanBorder";
import {StyledPlanBorder} from "./components/PlanBorder/PlanBorder.styles";
import styled from "styled-components";

const SubscriptionPlanContainer = styled(Stack)`
  &:hover {
    cursor: pointer;
  }
  
  &:hover ${StyledPlanBorder} {
    fill: #D1D3D4;
  }
`;

export const SubscriptionPlan: SubscriptionPlanComponent = ({
  planName,
  usersAmount,
  price,
  onClick,
  isLargeScreen,
  isMediumScreen,
  isSmallScreen,
  isSelected,
}) => {
  return (
    <SubscriptionPlanContainer
      onClick={onClick}
      alignItems="center"
      width={isSmallScreen ? '100%' : '30%'}
      mb={isSmallScreen ? 4 : 0}
    >
      <PlanTitleWrapper
        isLargeScreen={isLargeScreen}
        isMediumScreen={isMediumScreen}
        isSmallScreen={isSmallScreen}
      >
        {planName}
      </PlanTitleWrapper>
      <Stack position="relative" mb={2}>
        <PlanBorderContainer>
          <PlanBorder isSelected={isSelected} />
        </PlanBorderContainer>
        <PlanBodySeparator isMediumScreen={isMediumScreen}>
          <img src={planSeparator} alt="Subscription plan separator" />
        </PlanBodySeparator>
        <Stack position="absolute" pt="3.2vw" top={0} alignItems="center" width="100%">
          <Typography
            // @ts-ignore
            variant={isMediumScreen ? "planCard2" : "h5"}
          >
            {usersAmount}
          </Typography>
          <Box mb="1.515vw" />
          <Typography
            // @ts-ignore
            variant={isMediumScreen ? "planCard3" : "h6"}
            sx={{
              color: isSelected ? '#231F20' : '#77787B',
            }}
          >
            per year
          </Typography>
        </Stack>
      </Stack>
      <Stack alignItems="flex-start" width="100%">
        {/* @ts-ignore */}
        <Typography variant={isMediumScreen ? "planCard4" : "h7"}>
          {price}
        </Typography>
        {/* @ts-ignore */}
        <Typography variant={isMediumScreen ? "planCard5" : "caption1"}>
          VAT not included*
        </Typography>
      </Stack>
    </SubscriptionPlanContainer>
  );
};
