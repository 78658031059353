import {
  AgreementContainer, AgreementText, MAIN_WIDTH,
  ResponsiveContainer,
  StyledAccordion,
  SUBSCRIPTION_PLANS_WIDTH
} from "./Registration.styles";
import {
  Alert, Box,
  Button,
  Checkbox,
  FormControlLabel, Snackbar,
  Stack,
  Typography
} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { ChangeEvent, useState } from "react";
import * as Yup from 'yup';
import { Formik } from 'formik';
import { SubscriptionPlanEnum, UserData } from "./Registration.types";
import { SubscriptionPlan } from "../SubscriptionPlan/SubscriptionPlan";
import { SendInvoiceReq, useSendInvoice } from "../../hooks/useSendInvoice";
import { AxiosError } from "axios";
import { Iconify } from "../SubscriptionPlan/SubscriptionPlan.styles";
import { useNavigate } from "react-router-dom";
import { useSaveRegistrationData } from "../../hooks/useSaveRegistrationData";
import CircularProgress from '@mui/material/CircularProgress';
import {RegistrationForm} from "../RegistrationForm/RegistrationForm";
import logo from '../../assets/registrationLogo.svg';
import {agreementIndividual} from "../../assets/agreementIndividual";
import {agreementAcademics} from "../../assets/agreementAcademics";
import {agreementBusiness} from "../../assets/agreementBusiness";

export const Registration = () => {
  const navigate = useNavigate();
  const [expandedAccordion, setExpandedAccordion] = useState(0);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [isDocumentChecked, setIsDocumentChecked] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlanEnum | null>(null);
  const [mailFailureMessage, setMailFailureMessage] = useState<null | string>(null);
  
  const agreementsMap = {
    [SubscriptionPlanEnum.Individual]: agreementIndividual,
    [SubscriptionPlanEnum.Universities]: agreementAcademics,
    [SubscriptionPlanEnum.Business]: agreementBusiness,
  };
  
  const registrationSchema = Yup.object().shape({
    name: Yup
      .string()
      .min(1, 'Name should be longer than 1 character')
      .required('Required'),
    surname: Yup
      .string()
      .min(1, 'Surname should be longer than 1 character')
      .required('Required'),
    email: Yup
      .string()
      .email('Invalid email')
      .required('Required'),
    jobTitle: Yup
      .string()
      .min(1, 'Job title should be longer than 1 character')
      .required('Required'),
    company: Yup
      .string()
      .min(1, 'Company should be longer than 1 character')
      .required('Required'),
    companyAddress: Yup
      .string()
      .min(1, 'Company should be longer than 1 character')
      .required('Required'),
    registrationNumber: Yup
      .string()
      .min(1, 'Registration number should be longer than 1 character'),
    country: Yup
      .string()
      .min(1, 'Country should be longer than 1 character')
      .required('Required'),
    sector: Yup
      .string()
      .min(1, 'Country should be longer than 1 character')
      .required('Required'),
  });
  
  const handleAccordionChange = (index: number) => () => {
    if (index === expandedAccordion) {
      setExpandedAccordion(-1);
    } else {
      setExpandedAccordion(index);
    }
  };
  
  const {
    mutate: saveRegistrationData,
  } = useSaveRegistrationData();
  
  const handleSaveRegistrationData = (userData: UserData) => {
    saveRegistrationData(userData);
    setExpandedAccordion(1);
    setUserData(userData);
  };
  
  const handleDocumentCheck = (event: ChangeEvent<HTMLInputElement>) => {
    setIsDocumentChecked(event.target.value !== 'true');
    (event.target.value !== 'true') && setExpandedAccordion(3);
  };
  
  const handlePlanSelection = (plan: SubscriptionPlanEnum) => () => {
    setSelectedPlan(plan);
    setExpandedAccordion(2);
  };
  
  const {
    mutate: sendInvoice,
    isLoading,
  } = useSendInvoice({
    onSuccess: () => {
      navigate({
        search: '?status=mail-sent',
      });
    },
    onError: (error: AxiosError) => {
      setMailFailureMessage(error.message);
    },
  });
  
  const handleMailFailureClose = () => setMailFailureMessage(null);
  
  const handleSendInvoice = () => {
    sendInvoice({
      ...userData,
      plan: selectedPlan,
    } as SendInvoiceReq);
  };
  
  const isMobile = useMediaQuery(`(max-width:${MAIN_WIDTH}px)`);
  const isSmallScreen = useMediaQuery(`(max-width:${SUBSCRIPTION_PLANS_WIDTH}px)`);
  const isMediumScreen = useMediaQuery(`(min-width:${SUBSCRIPTION_PLANS_WIDTH}px) and (max-width: 2100px)`);
  const isLargeScreen = useMediaQuery(`(min-width: 2100px)`);
  
  if (isLoading) {
    return (
      <Box
        width="100vw"
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size={200} thickness={3} />
      </Box>
    );
  }
  
  return (
    <ResponsiveContainer>
      <Snackbar
        open={!!mailFailureMessage}
        autoHideDuration={6000}
        onClose={handleMailFailureClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert onClose={handleMailFailureClose} severity="error" sx={{ width: '100%' }}>
          {mailFailureMessage}
        </Alert>
      </Snackbar>
      <Typography
        variant="h3"
        textAlign="left"
        sx={{
          fontSize: isMobile ? '42px' : undefined,
        }}
      >
        Subscription purchase
      </Typography>
      <Box mb={8} />
      <StyledAccordion
        index={1}
        text="Fill the registration form below:"
        isOpen={expandedAccordion === 0}
        onChange={handleAccordionChange(0)}
        disabled={false}
        isSmallScreen={isSmallScreen}
        isMobile={isMobile}
      >
        <Formik
          initialValues={{
            name: '',
            surname: '',
            email: '',
            jobTitle: '',
            company: '',
            companyAddress: '',
            registrationNumber: '',
            country: '',
            sector: '',
          }}
          validationSchema={registrationSchema}
          onSubmit={handleSaveRegistrationData}
        >
          {({ errors, values, handleChange, handleSubmit, dirty }) => (
            <RegistrationForm
              errors={errors}
              values={values}
              handleChange={handleChange}
              dirty={dirty}
              handleSubmit={handleSubmit}
              isMobile={isMobile}
            />
          )}
        </Formik>
        <Box mb={3.5} />
        <img src={logo} alt="Logo" />
      </StyledAccordion>
      
      <StyledAccordion
        index={2}
        text="Select subscription plan:"
        isOpen={expandedAccordion === 1}
        onChange={handleAccordionChange(1)}
        disabled={!userData}
        isSmallScreen={isSmallScreen}
        isMobile={isMobile}
      >
        <Stack
          spacing={2}
          direction={isSmallScreen ? 'column' : 'row'}
          justifyContent={isSmallScreen ? 'start' : 'space-between'}
          alignItems="center"
        >
          <SubscriptionPlan
            planName="Business"
            usersAmount={5}
            price="£5,210"
            isSelected={selectedPlan === SubscriptionPlanEnum.Business}
            onClick={handlePlanSelection(SubscriptionPlanEnum.Business)}
            isLargeScreen={isLargeScreen}
            isMediumScreen={isMediumScreen}
            isSmallScreen={isSmallScreen}
          />
          <SubscriptionPlan
            planName="Academics"
            usersAmount={5}
            price="£2,600"
            isSelected={selectedPlan === SubscriptionPlanEnum.Universities}
            onClick={handlePlanSelection(SubscriptionPlanEnum.Universities)}
            isLargeScreen={isLargeScreen}
            isMediumScreen={isMediumScreen}
            isSmallScreen={isSmallScreen}
          />
          <SubscriptionPlan
            planName="Individual"
            usersAmount={1}
            price="£2,150"
            isSelected={selectedPlan === SubscriptionPlanEnum.Individual}
            onClick={handlePlanSelection(SubscriptionPlanEnum.Individual)}
            isLargeScreen={isLargeScreen}
            isMediumScreen={isMediumScreen}
            isSmallScreen={isSmallScreen}
          />
        </Stack>
      </StyledAccordion>
      
      <StyledAccordion
        index={3}
        text="Sign the agreement:"
        isOpen={expandedAccordion === 2}
        onChange={handleAccordionChange(2)}
        disabled={!userData || !selectedPlan}
        isSmallScreen={isSmallScreen}
        isMobile={isMobile}
      >
        <AgreementContainer alignItems="center">
          <AgreementText>
            {agreementsMap[selectedPlan as SubscriptionPlanEnum]}
          </AgreementText>
          <Box mb={4} />
          <FormControlLabel
            control={
              <Checkbox
                checked={isDocumentChecked}
                value={isDocumentChecked}
                onChange={handleDocumentCheck}
                sx={{
                  color: "#FF0000"
                }}
              />
            }
            label="I accept the terms and conditions"
            sx={{
              '.MuiFormControlLabel-label': {
                fontFamily: "Roboto",
                fontSize: "26px",
                fontWeight: "700",
                lineHeight: "30px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#FF0000"
              },
            }}
          />
        </AgreementContainer>
      </StyledAccordion>
  
      <StyledAccordion
        index={4}
        text="Select payment method:"
        isOpen={expandedAccordion === 3}
        onChange={handleAccordionChange(3)}
        disabled={!userData || !selectedPlan || !isDocumentChecked}
        isSmallScreen={isSmallScreen}
        isMobile={isMobile}
      >
        <Stack spacing={2} width="100%">
          {/* @ts-ignore */}
          <form action={`/create-checkout-session?plan=${selectedPlan}`} method="POST" style={{ width: '100%', display: 'flex' }}>
            <Button
              variant="outlined"
              type="submit"
              sx={{
                justifyContent: 'flex-start',
                flexGrow: 1,
              }}
              disabled={isLoading}
            >
              Pay with Stripe
              <Box mr={1} />
              <Iconify icon="logos:visa" />
              <Box mr={1} />
              {/* @ts-ignore */}
              <Iconify icon="logos:mastercard" width={30} height={20} />
            </Button>
          </form>
          <Button
            variant="outlined"
            type="button"
            onClick={handleSendInvoice}
            sx={{
              justifyContent: 'flex-start',
            }}
            disabled={isLoading}
          >
            Request invoice to email
            <Box mr={1} />
            {/* @ts-ignore */}
            <Iconify icon="ci:mail" width={24} height={24} />
          </Button>
        </Stack>
      </StyledAccordion>
    </ResponsiveContainer>
  );
}
