import React from 'react';
import './App.css';
import { Registration } from "./components/Registration/Registration";
import { Canceled } from "./components/Canceled/Canceled";
import { Success } from "./components/Success/Success";
import { SentMail } from "./components/SentMail/SentMail";
import { Route, Routes, useSearchParams } from 'react-router-dom';

function App() {
  const [ searchParams ] = useSearchParams();
  const status = searchParams.get("status");
  
  if (status === 'canceled') {
    return <Canceled />;
  }
  
  if (status === 'success') {
    return <Success />;
  }
  
  if (status === 'mail-sent') {
    return <SentMail />;
  }
  
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <Registration />
          }
        />
      </Routes>
    </div>
  );
}

export default App;
