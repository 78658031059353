export const agreementAcademics = `
REPORT ADVISER TM Subscription Agreement


Report Adviser Ltd (company number 09858276) (“Report Adviser”, “us” or “we”)
The details of the customer are contained in the Contract Details (“Customer” or “you”)
Parties
Report Adviser Ltd (company number 09858276) (“Report Adviser”, “us” or “we”)
The details of the customer are contained in the Contract Details. (“Customer” or “you”)

Background
A.  Report Adviser provides the proprietary integrated training e-management system known as the REPORT ADVISER System along with various other Services.
B.  The Customer wishes to use the REPORT ADVISER System and Services as part of its business.
C.  The Parties wish to agree on the terms and conditions below.
It is agreed
1.  Definitions and interpretation
1.1  Definitions
In this Agreement:
Agreement means this Agreement and any schedules or annexures.
Associated Body Corporate has the meaning given to that term in section 256 of the Companies Act 2006.
Business Day means a day on which banking institutions generally are open in London but excluding Saturdays, Sundays and public holidays.
Charges mean the rates and charges applicable to the usage of the REPORT ADVISER System and REPORT ADVISER Materials, as set out in the Contract Details.
Client Data means all data or information that you input, upload or otherwise submit into the REPORT ADVISER System for its operational use and includes information submitted via software interfaces (excluding any Submissions).
Commencement Date means the date indicated at the top of this page of this Agreement or, if no date is indicated, the date on which the last Party to sign this Agreement signed it.
Confidential Information means, in relation to each Party (for the purposes of this definition, the "Discloser"), all information disclosed by or on behalf of the Discloser, relating to:
(a)  Client Data, REPORT ADVISER Materials and the REPORT ADVISER System;
(b)  know-how, trade secrets, ideas, marketing strategies, operational information, technical information and financial information;
(c)  the business affairs or property of the Discloser, including any business, property or transaction in which the Discloser may be or may have been concerned or interested; and
(d)  other information, which, by its nature or by the circumstances of its disclosure, is or could reasonably be expected to be regarded as confidential;
whether such information was disclosed orally, in writing or in electronic or machine readable form, but excluding any such information:
(e)  which are Submissions;
(f)  which is publicly known;
(g)  which is disclosed to the other Party without restriction by a third party (other than the Discloser) and without any breach of confidentiality by that third party; or
(h)  which is developed independently by other Party without reliance on any of the Confidential Information.
Contract Details means the details of the Customer and the particular Services, Speciality, REPORT ADVISER System, Users and Charges applicable to the Customer in accordance with this Agreement, as set out in Schedule 1 or the applicable registration form on our Website.
Data Protection Law means the Data Protection Act 2018 and/or the General Data Protection Regulation (EU) 2016/679 as the case may require.
Effective Date means the date stated to be the effective date in the Contract Details.
Excluded Tax means a Tax imposed by a jurisdiction on, or calculated by reference to, the net income of a person in the jurisdiction because the person is a resident of or receives income from a source in or has some other connection with that jurisdiction, other than a Tax:
(a)  which is assessed by reference to the gross amount of a payment derived under this agreement or another document referred to in this agreement (without the allowance of a deduction); or
(b)  which is imposed on a person in the jurisdiction only because the person is a party to this agreement or a transaction contemplated by this agreement.
External Administrator means an administrator, controller, trustee, provisional liquidator, liquidator or any other person holding or appointed to an analogous office or acting or purporting to act in an analogous capacity.
Government Body means:
(a)  any person, body or other thing exercising an executive, legislative, judicial or other governmental function of any country or political subdivision of any country;
(b)  any public authority constituted by or under a law of any country or political subdivision of any country; and
(c)  any person deriving a power directly or indirectly from any other Government Body.
Insolvency Event means:
(a)  in relation to any corporation:
(1)  its liquidation;
(2)  an External Administrator is appointed in respect of the corporation or any of its property;
(3)  the corporation ceases or threatens to cease to carry on its business;
(4)  the corporation being deemed to be, or stating that it is, unable to pay its debts when they fall due;
(5)  any other ground for Liquidation or the appointment of an External Administrator occurs in relation to the corporation;
(6)  the corporation resolves to enter into Liquidation;
(7)  an application being made which is not dismissed or withdrawn within 10 Business Days for an order, resolution being passed or proposed, a meeting being convened or any other action being taken to cause or consider anything described in sub-paragraphs (1) to (6) (inclusive) above;
(b)  in relation to an individual, that person becoming an insolvent as defined in the Insolvency Act 1986 and Insolvency (England and Wales) Rules 2016;
(c)  in relation to any person, anything analogous to or having a similar effect to anything described above in this definition under the law of any relevant jurisdiction.
Intellectual Property Rights means all current and future registered and unregistered rights in respect of copyright, circuit layouts, designs, trade marks, know-how, confidential information, patents, inventions and discoveries and all other intellectual property as defined in article 2 of the convention establishing the World Intellectual Property Organisation 1967.
REPORT ADVISER Materials means any and all written materials provided by Report Adviser as part of the REPORT ADVISER System or Services.
REPORT ADVISER System means the proprietary integrated system for preparation of corporate social responsibility reports the details of which (including the Specialty) are set out on the Contract Details.
Law includes any requirement of any statute, rule, regulation, proclamation, order in council, ordinance or by‑law whether commonwealth, state, territorial or local.
Licence means the licence or licences at clause 3.1.
Loss means any and all losses (including loss of profit and loss of expected profit), claims, actions, Liabilities, damages, expenses, diminution in value or deficiencies of any kind (whether indirect, consequential or otherwise including all (on a full indemnity basis) and other expenses reasonably incurred in connection with investigating or defending any claims. A reference to Losses has a corresponding meaning.
Liabilities means any liabilities, debts or obligations, whether actual, contingent or prospective, present or future, qualified or unqualified or incurred jointly or severally with any other person. A reference to Liability has a corresponding meaning.
Party means either the Report Adviser or the Customer (as the context requires) and Parties means both of them.
Privacy Policy means the privacy policy of Report Adviser, as current from time to time, which is located on our Website.
Services mean the services (if any) provided by Report Adviser to you, as set out in the Contract Details.
Speciality means the speciality specified on the Contract Details, which determines the particular REPORT ADVISER System package applicable to this Agreement.
Submissions means all comments, feedback, suggestions, photos, e-mail and similar information or materials that you submit to Report Adviser regarding its products or services (including the REPORT ADVISER System or Services).
Tax means any present or future tax, levy, deduction, impost, withholding, charge or duty which is levied or imposed by any Government Body together with any interest, penalty or fine on those amounts.
Term means the overall term of this Agreement, incorporating the initial term specified in the Contract Details and any Further Term(s), in accordance with clause 2.
Users means the number of authorised users of the Licence, as set out in the Contract Details.
VAT means value added tax as provided by relevant UK or EU legislation including the Value Added Tax Act 1994 and/or Council Directive 2006/112/EC and/or other relevant legislation as the case may require.
Website means the Report Adviser website located at www.reportadviser.com.
1.2  Interpretation
(a)  the expressions "you" and "your" (and their grammatical equivalents) are a reference to the Customer.
(b)  the expressions "we", "us" and "our" (and their grammatical equivalents) are a reference to Report Adviser.
(c)  a reference to:
(1)  this Agreement or another document includes any variation or replacement of it notwithstanding any change in the identity of the Parties;
(2)  any statute, ordinance, code or other law includes regulations and other statutory instruments under any of them and consolidations, amendments re-enactments or replacement of any of them;
(3)  a person, firm, corporation, association or government body includes any other of them;
(d)  the singular includes the plural and vice versa;
(e)  headings shall not affect the construction;
(f)  where two or more persons are defined as a Party to this Agreement that term means each of the persons jointly, each of them severally and any two or more of them jointly;
(g)  an agreement, covenant, obligation, representation or warranty on the part of two or more persons binds them jointly and severally and an agreement, covenant, obligation, representation or warranty in favour of two or more persons is for the benefit of them jointly and severally;
(h)  the word “includes” or any of its grammatical equivalents are not to be construed as words of limitation.
1.3  Parties
(a)  If a Party consists of more than one person, this deed binds each of them separately and any two or more of them jointly.
(b)  An agreement, covenant, obligation, representation or warranty in favour of two or more persons is for the benefit of them jointly and each of them separately.
(c)  An agreement, covenant, obligation, representation or warranty on the part of two or more persons binds them jointly and each of them separately.
2.  Term
2.1  Term
This Agreement commences on the Commencement Date and remains in force until terminated or it expires in accordance with this Agreement.
2.2  Initial Term
The initial term of this Agreement:
(a)  commences on the Commencement Date; and
(b)  continues for the period of time specified in the Contract Details unless otherwise terminated earlier in accordance with this Agreement (Initial Term).
2.3  Further terms
(a)  The term of this Agreement will automatically renew for further monthly terms, on expiry of the Initial Term (Further Term) unless a Party provides the other Party with at least 10 Business Days’ written notice prior to the expiry of the then current term that the Agreement is to expire at the end of the then current term.
(b)  Each Further Term will be on the same terms as this Agreement subject to the inclusion of a new schedule of Charges as determined by Report Adviser in its discretion, and any other changes agreed in writing between the Parties at the time.
3.  Licence
3.1  Licence
You are granted a non-exclusive, non-transferable licence during the Term to use the REPORT ADVISER System, REPORT ADVISER Materials and Services for your internal business purposes, as limited by the number of Users (1 User).
3.2  No right of sub-licence
The Licence expressly excludes a right of sub-licence.
4.  Services
Report Adviser will provide the Services (if any) in the manner described in the Contract Details, during the Term.
5.  Warranties, acknowledgements and exclusions
5.1  Express warranty
Report Adviser will provide the REPORT ADVISER System, REPORT ADVISER Materials and Services to the Customer with due care and skill.
5.2  Exclusions
The Customer agrees and acknowledges that:
(a)  the REPORT ADVISER System, REPORT ADVISER Materials and Services are not free from faults or interruptions;
(b)  the Customer may not be able to use the REPORT ADVISER System and Services in some areas or at certain times;
(c)  Report Adviser has no control over, and is not responsible for, any faults caused by the use of products, services or software not supplied by Report Adviser for the use in connection with the REPORT ADVISER System, REPORT ADVISER Materials and Services;
(d)  REPORT ADVISER System will not operate effectively on internet browsers whose specifications are less then Internet Explorer 8, Firefox 3.6 or Google Chrome, or any other browser or platform notified to the Customer from time to time;
(e)  Report Adviser is not liable or responsible for the accuracy, currency, completeness or relevance of any report provided or generated using the REPORT ADVISER System, REPORT ADVISER Materials and Services (if any);
(f)  Report Adviser does not endorse the relevant competence of any Customer or its Users using the REPORT ADVISER System, REPORT ADVISER Materials and Services (if any).
5.3  Intended purpose
The Customer must not use the REPORT ADVISER System, REPORT ADVISER Materials and Services (if any) for any purpose other than their intended purpose.
5.4  Resale
Unless specifically authorised to do so by Report Adviser in writing, the Customer must not on-sell the REPORT ADVISER System, REPORT ADVISER Materials and Services to any person.
5.5  Compliance with law
The Customer agrees that it must comply with all Laws applicable to its use of the REPORT ADVISER System, REPORT ADVISER Materials and Services.
6.  Charges and payment
6.1  Charges
(a)  The Charges are the rates and fees that you must pay for the use of the REPORT ADVISER System, REPORT ADVISER Materials and the Services.
(b)  You acknowledge and agree that, before entering into this Agreement, you are aware of the Charges applicable to the use of the REPORT ADVISER System, REPORT ADVISER Materials and the Services.
(c)  The applicable Charges for the REPORT ADVISER System are calculated on a per user basis, annually.
(d)  The Customer can purchase additional Licences in accordance with the mechanism specified in the Contract Details.
6.2  Invoices
(a)  Report Adviser will issue you an invoice for the Charges upfront, annually in advance on and from the Commencement Date.
(b)  The invoice can be sent to you either via post or email.
(c)  If you believe that the Charges detailed in an invoice are incorrect, you must provide notice to Report Adviser prior to the date by which that invoice is due.
(d)  The procedure for dealing with disputed invoices is as follows:
(1)  Report Adviser will attempt to respond to you in writing within thirty (30) days of receipt of the notice of dispute referred to in clause (c) above, and advise you of the action being taken to resolve the dispute.
(2)  Notwithstanding clause 6.2(d)(1), failure to respond within thirty (30) days from receipt shall not breach Report Adviser’s obligations under this Agreement.
(3)  If Report Adviser accepts your claim that an invoice is incorrect, then it will issue a credit note within thirty (30) days from acceptance.
6.3  Payment
(a)  The Customer must pay Report Adviser the total Charges for the yearly fee of £2,150 until the end of the term of the subscription.
(b)  If you pay an invoice by credit card, B-pay or cheque, Report Adviser may charge you a processing fee, but only where Report Adviser is charged by a third party for such a payment mechanism.
6.4  Late or non payment of invoices
If you fail to pay an invoice within the time period referred in clause 6.3 above, then Report Adviser may do any one or more of the following:
(a)  charge you interest at a rate of 1% a month on the amount owing in the invoice;
(b)  charge you an administrative fee, in order to compensate Report Adviser for the loss it suffers as a result of the late payment; and
(c)  restrict or suspend the REPORT ADVISER System, REPORT ADVISER Materials and/or Services, or terminate the Agreement in accordance with clause 13 below.
6.5  Refund of credit
If Report Adviser overcharge you for the provision of the REPORT ADVISER System, REPORT ADVISER Materials and/or Services, Report Adviser will refund those monies to you within 30 days of it being made aware of such overcharge.
7.  Intellectual Property Rights
7.1  Ownership
(a)  All Intellectual Property Rights in the REPORT ADVISER System and REPORT ADVISER Materials are owned by (or licensed to) Report Adviser.
(b)  Nothing in this Agreement transfers the ownership of, or grants any licence to, any of the Intellectual Property Rights in the REPORT ADVISER System or REPORT ADVISER Materials. All Intellectual Property Rights in the REPORT ADVISER System and REPORT ADVISER Materials belong to, and remain vested in, Report Adviser or its Associated Bodies Corporate or licensors.
7.2  Client Data
Subject to clause 8, all Intellectual Property Rights in the Client Data belong to, and remain vested in, you or your nominated entities and you remain solely responsible for such Client Data to the extent permitted by law.
7.3  Prohibited activities
You must not:
(a)  do or permit or omit to do any act which infringes our Intellectual Property Rights;
(b)  reverse engineer any of the Intellectual Property Rights in the REPORT ADVISER System or REPORT ADVISER Materials; or
(c)  decompile any software which forms part of the REPORT ADVISER System or REPORT ADVISER Materials.
7.4  Notification of infringement claim
You must notify us within 3 Business Days if you become aware of:
(a)  any actual or suspected infringement by a third party of our Intellectual Property Rights; or
(b)  any actual or threatened claim by a third party that its Intellectual Property Rights have or will be infringed by any act, omission or permission by a Party in connection with this Agreement.
7.5  Indemnity
You agree to indemnify us fully against all liabilities and Losses which we may incur (including, but not limited to, to any third party) as a result of your breach of clauses 7.2, 7.3 or 7.4.
8.  Submissions
8.1  Ownership and confidentiality
Submissions will not be confidential and will become our property upon submission to us. By providing any Submissions, you assign to us, at no charge, all Intellectual Property Rights in the Submissions.
8.2  Warranty
You warrant to us that any Submissions by you will not infringe the Intellectual Property Rights of any other person, or be defamatory or offensive.
9.  Website maintenance
9.1  Suspension or modification
We reserve the right for any reason, at any time without notice, to modify, suspend access to, or restrict the functionality of, the REPORT ADVISER System and/or the Website (or any part thereof), or to cease providing the REPORT ADVISER System and/or the Website. We will endeavour to provide prior notice of such circumstances, however we are unable to guarantee that such prior notice will be given.
9.2  Maintenance
In particular (but without limitation to the generality of clause 9.1 above), you acknowledge and agree that we may, at any time without notice, modify, suspend access to, or to restrict the functionality of, the REPORT ADVISER System and/or Website to carry out scheduled, preventative or corrective maintenance of the REPORT ADVISER System and/or Website. We will endeavour to provide prior notice to you of such circumstances, however we are unable to guarantee that such prior notice will be given.
9.3  Access to account
We may from time to time need to access your account in order to conduct maintenance or to monitor your compliance with the terms of this Agreement. You consent to us accessing your account for these purposes.
9.4  No liability
You agree that we shall not be liable to you or any third party for any Loss arising out of Report Adviser exercising its rights under clauses 9.1, 9.2 or 9.3 to the extent permitted by law.
10.  Confidential information
10.1  Disclosure
(a)  A Party must not, without the prior written approval of the other Party, disclose the other Party's Confidential Information.
(b)  Each Party must take all reasonable steps to ensure that its employees and agents, and any sub-contractors engaged for the purposes of this Agreement, do not make public or disclose the other Party's Confidential Information.
(c)  A Party is not in breach of this clause 10.1 in circumstances where it is legally compelled to disclose the other Party's Confidential Information.
(d)  Notwithstanding any other provision of this clause 10.1, the Parties may disclose the terms of this Agreement (other than Confidential Information of a technical nature) to its related companies, solicitors, auditors, insurers and accountants.
10.2  Return of Confidential Information
Each Party must on demand, return to the other Party any Confidential Information supplied by the other Party in connection with this Agreement.
10.3  Security and unauthorised access
Each Party must ensure that all information and materials of the other Party (or its agents or contractors) in the custody of that Party for purposes connected with this Agreement are protected at all times from unauthorised access or use by a third party, and from misuse, damage or destruction by any person.
10.4  Survival
This clause survives the termination of this Agreement.
11.  General obligations
The Customer agrees and acknowledges that:
(a)  it must fully co-operate with Report Adviser and its nominees, in relation to the use of the REPORT ADVISER System and/or Website;
(b)  it must notify us of any change in the details provided to us in the Contract Details;
(c)  at all times during the Term and for 1 year thereafter, grant to Report Adviser and/or its authorised representatives or agents, the right of reasonable access to any records, sites and materials that are related to this Agreement;
(d)  it is responsible for ensuring the accuracy of any data provided to Report Adviser to be entered into the REPORT ADVISER System whether by Report Adviser or any authorised third party;
(e)  it is responsible for the accuracy of any data the Customer or its Users may enter into the REPORT ADVISER System;
(f)  Report Adviser is not liable in any way for any errors, omissions or delays that may result from Report Adviser inputting any data it may receive from the Customer into the REPORT ADVISER System; and
(g)  in the event there are errors and/or omissions in the data inputted into the REPORT ADVISER System by Report Adviser or any authorised third party, whether due to the inaccuracy of the data provided by the Customer or otherwise, Report Adviser reserves the right to charge the Customer an additional administrative fee where it takes steps to rectify the errors and/or omissions.
12.  Limitation of liability
12.1  Application of clause
The application of this clause applies to any Loss or Liability arising under, or in connection with, this Agreement and/or the use of the REPORT ADVISER System, REPORT ADVISER Materials, Services and/or Website.
12.2  Exclusion of liability
To the extent permitted by Law, in no event will Report Adviser be liable to you for:
(a)  Loss of profits, business, revenue, goodwill or anticipated savings;
(b)  Loss, to the extent that such loss is caused by your negligence (or your employees, agents or contractors);
(c)  Loss, to the extent that it results from your failure to take reasonable steps to avoid that loss;
(d)  Loss, to the extent that it results from modifications made to the REPORT ADVISER System, REPORT ADVISER Materials and/or Website by you (or your employees, agents or contractors);
(e)  Personal injury or death; and/or
(f)  Indirect or consequential loss or damage of any kind;
even if Report Adviser has been made aware of the possibility of such loss prior to entering into this Agreement.
12.3  Limitation of liability
(a)  To the extent Report Adviser is found liable in connection with this Agreement its liability shall be limited to resupplying, repairing or replacing the relevant goods or services.
(b)  To the extent that clauses 12.2 or 12.3(a) do not apply, then Report Adviser’s cumulative liability in the aggregate (to the fullest extent permitted by law) shall in no event exceed the sum of Charges paid under this Agreement.
13.  Termination
Either Party may terminate this Agreement immediately by written notice upon the occurrence of one of the following events:
(a)  if the other Party is in breach of this Agreement and that other Party has failed to remedy the breach within twenty (20) days of a written notice to it from the first-mentioned Party, specifying the breach and requiring its remedy;
(b)  if the other Party is in breach of this Agreement and that breach is not capable of remedy; or
(c)  an Insolvency Event occurs in respect of the other Party.
14.  Consequences of termination
Upon termination or expiration of this Agreement:
(a)  we will cease performing, and providing you with access to, the REPORT ADVISER System, the REPORT ADVISER Materials, subject to (d) below, and the Services;
(b)  All of the Licences terminate;
(c)  You must return to us or (if requested by us) destroy, all Confidential Information belonging to us;
(d)  the REPORT ADVISER Materials will be available for a period of 14 days after termination or expiration of this Agreement, during which time the Customer may save or print any reports, files and/or materials held within the REPORT ADVISER Material. After the expiry of the 14 days period, the Customer’s access will be disabled and Report Adviser will no longer have any Liability in respect of any REPORT ADVISER Materials; and
(e)  You must grant us and/or our authorised representatives or agents, the right of reasonable access to any records, sites and materials that are related to this Agreement.
15.  Privacy
15.1  Privacy policy
(a)  Our privacy policy appears on our website and governs the collection, purpose and use of personal information by Report Adviser generally.
(b)  Our privacy policy is a compliance document prescribed by law rather than a legal contract between two or more persons. However, for the purposes of this Agreement, you acknowledge and agree that:
(1)  you have been provided with access to our Privacy Policy;
(2)  you have read and understood our Privacy Policy;
(3)  you contractually agree to the consents provided by you in the Privacy Policy; and
(4)  nothing in the Privacy Policy gives rise to contractual obligations on Report Adviser.
15.2  Data Protection Laws
You must comply, and procure that your Users will comply, with all Data Protection Laws under or in connection with this Agreement.
16.  VAT
16.1  VAT exclusive
Unless expressly stated to the contrary all amounts expressed in this Agreement are exclusive of VAT.
16.2  Recipient to pay Supplier
If a party (Supplier) is obliged under relevant VAT law to pay an amount of VAT for a taxable supply made by the Supplier to another party (Recipient) under this agreement, the Recipient must pay the Supplier an amount equal to the VAT payable on the supply by the Supplier.
16.3  Time for payment
The Recipient must pay the amount referred to in clause 16.2 and any interest, penalty, fine or expense relating to the VAT, in addition to and at the same time as the consideration otherwise payable by the Recipient for the supply.
17.  Governing law
This Agreement is governed by and construed in accordance with the laws of England and Wales.
18.  Miscellaneous
18.1  No assignment
A Party must not assign, transfer or novate all or any part of its rights or obligations under or relating to this Agreement or grant, declare, create or dispose of any right or interest in it, without the prior written consent of each other Party.
18.2  Remedies cumulative
The rights and remedies under this Agreement are cumulative and not exclusive of any rights or remedies provided by law.
18.3  Severability
If anything in this Agreement is or is determined to be unenforceable, illegal, voidable or void in a jurisdiction then it is severed for that jurisdiction and the rest of this Agreement remains in full force and effect in all other jurisdictions.
18.4  Further assurance
Each Party must promptly at its own cost do all things (including executing and delivering all documents) necessary or desirable to give full effect to this Agreement and the transactions contemplated by it.
`;
