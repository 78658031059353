// @ts-nocheck
import { Box, Typography } from "@mui/material";
import confirmationSign from '../../assets/confirmationSign.svg';
import {SuccessBorder} from "./Success.styles";

export const Success = () => {
  return (
    <Box
      height="100vh"
      width="100vw"
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
    >
      <SuccessBorder>
        <img src={confirmationSign} alt="Confirmation sign" />
        <Box mb={2} />
        <Typography variant="success1">
          The payment is successful!
        </Typography>
        <Box mb={4} />
        <Typography variant="success2">
          Our manager will send an email when your subscription will be activated.
        </Typography>
      </SuccessBorder>
    </Box>
  );
};
