import {Link, Stack, styled} from "@mui/material";

export const StyledLink = styled(Link)<{
  isMobile: boolean,
}>`
  text-decoration: none;
  color: #B1B3B6;

  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 700;
  font-size: ${({ isMobile }) => isMobile ? '26px' : '30px'};
  line-height: 35px;
  
  :hover {
    color: #FF0000;
  }
`;

export const StyledSuccessLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.success.main};
  
  :hover {
    color: ${({ theme }) => theme.palette.success.dark};
  }
`;

export const CanceledBorder = styled(Stack)`
  border: dashed #FF0000;
  border-radius: 4px;
  padding: 45px;
  position: relative;
  max-width: min(750px, 80vw);
  
  img#canceled-failure-icon {
    position: absolute;
    z-index: 2;
    background: white;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
